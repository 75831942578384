module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HyPUCP","start_url":"/","short_name":"HyPUCP","theme_color":"#00b4d6","background_color":"#00b4d6","display":"standalone","path":"/Users/josejollja/Documents/PUCP/landing-pucp/src/images","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e6b6ec4dff64b62a3d083ad5b15469f7"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
